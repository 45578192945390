import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Login() {

	const navigate = useNavigate();

	const [un, setUn] = useState('');
	const [pw, setPw] = useState('');

	const usernameChange = (e) => { setUn( e.target.value ) };
	const passwordChange = (e) => { setPw( e.target.value ) };

	const handleSubmit = (event) => {
    event.preventDefault();

		let data = {
			email: un,
			password: pw
		}

		fetch('https://api.pseudonym.mx/api/auth/login', {
			method: "POST",
			headers: {
      	"Content-Type": "application/json",
      	// 'Content-Type': 'application/x-www-form-urlencoded',
    	},
			body: JSON.stringify(data)
		}).then(response => {
			console.log( response )
			return response.json()
		}).then(response => {
			console.log( response )

			if( response.token ) {
				localStorage.setItem("nymJwt", response.token);
				navigate("/dash");
			}

		})
  };

  return (
		<Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={usernameChange} />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" onChange={passwordChange} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default Login;
