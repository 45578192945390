import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';

function Root() {
  return (
		<Container fluid>
			<Outlet />
		</Container>
  );
}

export default Root;
